<template>
	<div>
		<div v-if="guide === 0" class="bg_shadow fade type-input" >
			<div class="center_login" v-if="showLogin === true" style="position:relative;">
				<div style="width: 100%;display: flex">
					<!--    左侧        -->
					<div style="width: 40%;height:100%;">
						<div style="display: flex;justify-content: center;text-align: center;padding-top: 70px">
							<div style="color: #444444;font-size: 20px;height: 21px;font-weight: bold;">
								百工宝App扫码登陆
							</div>
						</div>
						<div style="text-align: center;display: flex;justify-content: center;margin-top: 70px;width: 100%;">
							<img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/ewm/xcx.jpg" style="width: 50%;height: 50%;">
						</div>
						<div style="display: flex;justify-content: center;align-content: center">
							<div style="position: absolute; bottom: 50px;">
								<div style="float: left"><el-button type="success" round>下载百工宝App</el-button></div>
								<div style="float: left;margin: 0 8px"><el-button type="primary" round @click="toReg(2)">机构注册</el-button></div>
								<div style="float: left"><el-button type="primary" round @click="toReg(1)">个人注册</el-button></div>
							</div>
						</div>
					</div>
					<!--  中心线   -->
					<div style="width: 1%;height: 520px;border-left: 1px solid #E6E6E6;text-align: center"></div>
					<div style="width: 59%">
						<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
						<div style="margin-top: 70px;margin-left: 15px;" v-for="(it,k) in Login_list" :key="k">
							<div @click="login_title(it.type)" style="float: left;height: 30px;margin-left: 20px;text-align: center" :class="{ active: it.type === cache }">
								<div class="Login">{{it.name}} </div>
							</div>
						</div>
						<div v-if="cache == 2" style="clear: both;padding: 10px;">
							<div style="width: 400px;margin-top: 30px;text-align: center;margin-left: 20px;">
								<el-input placeholder="请输入账号"  type="text" v-model="number">
									<template slot="prepend">账号</template>
								</el-input>
								<div style="margin-top: 15px">
									<el-input placeholder="请输入密码" :maxlength="20"  type="text" v-model="cipher" show-password>
										<template slot="prepend">密码</template>
									</el-input>
								</div>
							</div>
						</div>
						<div v-if="cache == 1" style="clear: both;padding: 10px;">
							<div style="width: 400px;margin-top: 30px;text-align: center;margin-left: 20px;">
								<el-input placeholder="请输入手机号"  autocomplete="new-password" :maxlength="11" type="text" v-model="phone">
									<template slot="prepend">中国 +86 <span style="color: #E6E6E6">|</span></template>
								</el-input>
								<div style="margin-top: 15px">
									<el-input placeholder="输入6位短信验证码"  autocomplete="new-password" :maxlength="6" type="text" v-model="code">
										<template slot="append">
											<el-button
													style="border-radius: 0 4px 4px 0;"
													:disabled="countdown>0"
													@click="sendVerificationCode"
											>
												{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
											</el-button>
										</template>
									</el-input>
								</div>
							</div>
						</div>
						<div style="text-align: center;margin-top: 20px;margin-right: 10px" >
							<el-button @click="accoun_login_code" v-if="cache == 1" style="width: 400px;height: 40px;font-size: 18px;" type="primary">登录</el-button>
							<el-button @click="pwd_login" v-if="cache == 2" style="width: 400px;height: 40px;font-size: 18px;" type="primary">登录</el-button>
						</div>
						<div style="float: right;height: 20px;">
							<div @click="forgotTologin" style="border-radius: 0 4px 4px 0;margin-right: 30px;margin-top: 7px;cursor: pointer;color: #1C7EFF">
								忘记密码？
							</div>
						</div>
						<div style="display: flex;justify-content: center;margin-top: 40px;">
							<div style="border-top: 2px solid #E6E6E6;width: 134px;margin-right: 20px"></div>
							<div style="color: #999999;height: 18px;font-size: 15px;margin-top: -10px">其它方式登录</div>
							<div style="border-top: 2px solid #E6E6E6;width: 128px;margin-left: 20px;"></div>
						</div>
						<div style="display: flex;justify-content: center;text-align: center;margin-top: 20px;">
							<div style="width: 40px;height: 40px;">
								<img @click="LoginWXClick" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/VX.png" style="width: 40px;height: 40px;">
							</div>
							<div style="width: 40px;height: 40px;margin-left: 40px;">
								<img @click="LoginQQClick" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/QQ.png" style="width: 40px;height: 40px;">
							</div>
						</div>
						<div style="width: 400px;margin-left: 17px;font-size: 14px;margin-top: 15px;">
							<p style="color: #999999;">登陆即代表您同意
								<a style="color:blueviolet;"  target=_blank href="https://oss.baigongbao.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E5%88%9B%E4%BD%9C%E8%80%85%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf">《百工宝创作者用户协议》</a>
								<a style="color:blueviolet;" target=_blank  href="https://oss.baigongbao.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%8D%8F%E8%AE%AE.pdf">《百工宝平台隐私保护协议》</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="center_login" v-if="showLogin === false" style="position:relative;">
				<div style="padding: 20px">
					<div style="display: flex;">
						<div class="hand op" @click="showLogin = true" style="font-size: 18px; font-weight: 600">< 返回</div>
						<div style="font-size: 18px; font-weight: 600; width: 80%; text-align: center">该手机号下绑定了多个账号，请选择登录账号</div>
					</div>
					<div style="margin-top: 30px">
						<div :class="{'select-border': selectLogin === index}" v-for="(item, index) in userOptions" :key="index" class="card hand">
							<div style="display: flex; justify-content: space-between; width: 100%; font-size: 16px; font-weight: 600; line-height: 30px">
								<div style="display: flex; width: 70%">
									<div style="width: 45%">
										<el-radio v-model="selectLogin" :label="index" :disabled="item.isDisabled" @change="changeSelect(index)">{{item.accoun}}</el-radio>
									</div>
									<div style="display: flex; width: 55%">
										<img :src="item.headIco" style="width: 30px; height: 30px; margin-right: 10px; border-radius: 50%">
                                        <div v-if="item.isDisabled == false">{{item.nickName}}</div>
                                        <div v-if="item.isDisabled == true">{{item.nickName}}<span style="color: red;">({{item.describe}})</span></div>
									</div>
								</div>

								<div style="display: flex;">
									<div class="btn" style="color: #FF9800; border: 2px #FF9800 solid; margin-right: 10px" v-if="index === 0">上次登录</div>
									<div class="btn" style="color: #4CAF50; border: 2px #4CAF50 solid" v-if="item.userType ===1"><i class="el-icon-user-solid"></i>个人账号</div>
									<div class="btn" style="color: #2E57EE; border: 2px #2E57EE solid" v-if="item.userType ===2"><i class="el-icon-s-shop"></i>企业账号</div>
									<div class="btn" style="color: #409EFF; border: 2px #409EFF solid" v-if="item.userType ===3">机构子账号</div>
								</div>
							</div>
							<div class="triangle" v-if="selectLogin === index">
								<i class="el-icon-check tr-icon"></i>
							</div>
						</div>
					</div>
					<div class="hand op" @click="toLogin" style="position: absolute; bottom: 30px; left: 25%; font-size: 18px; border-radius: 5px; font-weight: 600; text-align: center; background-color: #2E57EE; color: #FFFFFF; padding: 6px 0; width: 400px">进入百工宝</div>
				</div>
			</div>
		</div>

		<!--  手机号绑定  -->
		<div v-if="guide === 2" class="bg_shadow fade type-input">
			<div class="center_phone" style="position:relative;">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">绑定手机号</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
					<div style="color: #999999">绑定手机号以解锁更多百工宝的功能</div>
				</div>
				<div style="padding: 30px">
					<div style="clear: both;padding: 10px;">
						<div style="text-align: center;">
							<el-input placeholder="请输入手机号" auto-complete="off" :maxlength="11" type="text" v-model="phone">
								<template slot="prepend">中国 +86 <span style="color: #E6E6E6">|</span></template>
							</el-input>
							<div style="margin-top: 14px">
								<el-input placeholder="输入6位短信验证码" :maxlength="6" type="text" v-model="code">
									<template slot="append">
										<el-button
												style="border-radius: 0 4px 4px 0;width: 120px; line-height: 2px; margin-top: 1px"
												:style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '30px' : '32px', }"
												:disabled="countdown>0"
												@click="sendVerificationCode"
										>
											{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
										</el-button>
									</template>
								</el-input>
							</div>
							<div style="text-align: center;margin-top: 40px;">
								<el-button @click="submitPhone" style="width: 320px;height: 36px; line-height: 10px; font-size: 18px;" type="primary">确认</el-button>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<!--        设置账号信息-->
		<div v-if="guide === 1" class="bg_shadow fade">
			<div class="center_phone" style="position:relative;top: -100px;">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">设置账号信息</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
					<div style="color: #999999">设置账号密码以确保百工宝账号的安全</div>
				</div>
				<div style="padding: 20px 30px 30px 30px; max-height: 60vh; overflow: auto" >
					<div>
						<div class="info-title">
							修改昵称
						</div>
						<div>
							<el-input placeholder="设置自己的昵称" type="text" v-model="accountInfo.nickName">
							</el-input>
						</div>
					</div>
					<div style="margin-top: 20px">
						<div class="info-title">
							登录账号
						</div>
						<div>
							<el-input placeholder="请设置用于登录百工宝的账号(8-20个字母和数字的组合)" type="text" v-model="accountInfo.account">
							</el-input>
						</div>
					</div>
					<div style="margin-top: 20px">
						<div class="info-title">
							账号密码
						</div>
						<div>
							<el-input @input="changePassword" :show-password="true" placeholder="请设置您的账号密码" type="password" v-model="accountInfo.password">
							</el-input>
						</div>
						<div style="margin-top: 2px; font-size: 12px;">
							<span v-for="(item, index) in pwdRuleList" :key="index" style="margin-right: 30px" :style="item.status === true ? 'color: #999999' : 'color: #DD0D04'"><i class="el-icon-warning-outline"></i> {{item.name}}</span>
						</div>
					</div>
					<div style="margin-top: 20px">
						<div class="info-title">
							确认密码
						</div>
						<div>
							<el-input :show-password="true" placeholder="请再次输入您的账号密码" type="password" v-model="accountInfo.confirmPassword">
							</el-input>
						</div>
					</div>
					<div style="text-align: center;margin-top: 40px;">
						<el-button @click="submitAccountInfo" style="width: 320px;height: 40px; line-height: 12px; font-size: 18px;" type="primary">确认</el-button>
					</div>
				</div>
			</div>
		</div>

		<!--认证-->
		<div v-if="guide === 7" class="bg_shadow fade">
			<div class="center_phone" style="position:relative; top: -100px; ">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">认证实名信息</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
					<div style="color: #999999; padding: 0 48px">根据国家相关法律规定，您必须经过实名认证后
						方可发布公共性信息。另外您可以完整体验
						朋友圈、room、shop和钱包的功能</div>
				</div>
				<div style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
					<div v-if="!qrShow">
						<el-form :model="nameForm" :rules="nameRules" ref="resumeForm">
							<div>
								<div class="info-title">
									真实姓名
								</div>
								<el-form-item prop="realName">
									<el-input :disabled="first === true" class="input" placeholder="请输入您的真实姓名" v-model="nameForm.realName" maxlength="20"></el-input>
								</el-form-item>
							</div>
							<div style="margin-top: 20px">
								<div class="info-title">
									身份证号
								</div>
								<el-form-item prop="idCardNo">
									<el-input
											:disabled="first === true"
											:show-word-limit="true" class="input"
											placeholder="请输入正确的18位身份证号码"
											v-model="nameForm.idCardNo" maxlength="18"></el-input>
								</el-form-item>
							</div>
						</el-form>
						<div style="display: flex; justify-content: end;">
							<el-button type="primary" class="save-btn" @click="updateInfo">下一步</el-button>
						</div>
					</div>
					<div v-if="qrShow">
						<div v-if="qrShow" style="text-align: center">
							<vue-qr
									:text="qr"
									:size="250"
									:logoScale="0.2">
							</vue-qr>
						</div>
						<div style="display: flex; justify-content: end;">
							<el-button type="primary" class="save-btn" @click="onClose">完成</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--企业认证-->
		<div v-if="guide === 8" class="bg_shadow fade">
			<div class="center_phone" style="position:relative; top: -100px; ">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">企业认证实名信息</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
					<div style="color: #999999; padding: 0 48px">根据国家相关法律规定，您必须经过实名认证后
						方可发布公共性信息。另外您可以完整体验
						room、shop和钱包的功能</div>
				</div>
				<div v-if="qrShow === false" style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
					<el-form :model="organForm" :rules="organRules" ref="organForm">
						<div>
							<div class="info-title">
								法人身份证号
							</div>
							<el-input type="textarea"
									  resize="none"
									  :rows="1"
									  v-model="organForm.idCardNo"
									  maxlength="18"
									  :show-word-limit="true"
									  :disabled="first === true"
									  style="margin-right: 16px; line-height: 22px; width: 300px">
							</el-input>
						</div>

						<div style="margin-top: 20px">
							<div class="info-title">
								上传企业营业执照
							</div>
							<el-upload accept="image/*" class="upload-demo" action="" :show-file-list="false" drag :http-request="($event)=>uploadImg($event, 'codeImg')" :on-remove="$event => removeImg($event, 'codeImg')">
								<img v-if="organForm.url" width="100%" height="338px" :src="organForm.url"/>
								<i class="el-icon-plus"></i>
								<div style="font-size: 16px; color: #999999">点击或拖动上传图片</div>
							</el-upload>
						</div>
					</el-form>
					<div style="display: flex; justify-content: end; margin-top: 20px">
						<el-button type="primary" class="save-btn" @click="updateOrganInfo">下一步</el-button>
					</div>
				</div>
				<div v-if="qrShow === true" style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
					<div v-if="qrShow" style="text-align: center">
						<vue-qr
								:text="qr"
								:size="250"
								:logoScale="0.2">
						</vue-qr>
					</div>
					<div style="display: flex; justify-content: end; margin-top: 20px">
						<el-button type="primary" class="save-btn" @click="onClose">完成</el-button>
					</div>
				</div>
        <div v-if="qrShow === true" style="padding: 20px 30px 30px 30px; max-height: 600px; overflow: auto">
          <div v-if="qrShow" style="text-align: center">
            <vue-qr
                :text="qr"
                :size="250"
                :logoScale="0.2">
            </vue-qr>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <el-button type="primary" class="save-btn" @click="onClose">完成</el-button>
          </div>
        </div>
			</div>
		</div>

<!--		&lt;!&ndash;        绑定微信&ndash;&gt;-->
<!--		<div v-if="guide === 3" class="bg_shadow fade">-->
<!--			<div class="center_phone" style="position:relative;">-->
<!--				<div style="text-align: center;">-->
<!--					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">微信扫码绑定</div>-->
<!--					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>-->
<!--				</div>-->
<!--				<div style="padding: 20px 30px 30px 30px">-->
<!--					<div style="text-align: center" @click="bindWx()">点击绑定微信</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

<!--		&lt;!&ndash;        绑定QQ&ndash;&gt;-->
<!--		<div v-if="guide === 4" class="bg_shadow fade">-->
<!--			<div class="center_phone" style="position:relative;">-->
<!--				<div style="text-align: center;">-->
<!--					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">QQ扫码绑定</div>-->
<!--					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>-->
<!--				</div>-->
<!--				<div style="padding: 20px 30px 30px 30px">-->
<!--					<div style="text-align: center" @click="bindQQ()">点击绑定QQ</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

		<!--修改密码-->
		<div v-if="guide === 6" class="bg_shadow fade">
			<div class="center_phone" style="position:relative;">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">修改账户密码</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
				</div>
				<div style="padding: 20px 30px 30px 30px">
					<div>
						<div style="margin-bottom: 20px">
							<div class="info-title">
								旧密码
							</div>
							<div>
								<el-input :show-password="true" placeholder="请输入旧的账号密码" type="password" v-model="passwordForm.oldPassword">
								</el-input>
							</div>
						</div>
						<div class="info-title">
							新密码
						</div>
						<div>
							<el-input @input="changePassword" :show-password="true" placeholder="请设置新的账号密码" type="password" v-model="passwordForm.newPassword">
							</el-input>
						</div>
						<div style="margin-top: 2px; font-size: 12px;">
							<span v-for="(item, index) in pwdRuleList" :key="index" style="margin-right: 30px" :style="item.status === true ? 'color: #999999' : 'color: #DD0D04'"><i class="el-icon-warning-outline"></i> {{item.name}}</span>
						</div>
					</div>
					<div style="margin-top: 20px">
						<div class="info-title">
							确认密码
						</div>
						<div>
							<el-input :show-password="true" placeholder="请再次输入您的账号密码" type="password" v-model="passwordForm.confirmPassword">
							</el-input>
						</div>
					</div>
					<div style="text-align: center;margin-top: 40px;">
						<el-button @click="submitPasswordInfo" style="width: 320px;height: 40px; line-height: 12px; font-size: 18px;" type="primary">确认</el-button>
					</div>
				</div>
			</div>
		</div>

		<!--绑定邮箱-->
		<div v-if="guide === 5" class="bg_shadow fade">
			<div class="center_phone" style="position:relative;">
				<div style="text-align: center;">
					<div style="padding: 26px 0 10px 0; font-size: 20px; font-weight: 600">绑定邮箱</div>
					<i class="el-icon-close op sc hand" style="color:#666666;font-size:30px;position:absolute;right:10px;top:10px;" @click="onClose"></i>
				</div>
				<div style="padding: 20px 30px 30px 30px">
					<div style="clear: both;padding: 10px;">
						<div style="text-align: center;">
							<el-input placeholder="请输入邮箱账号" auto-complete="off" type="text" v-model="email">
							</el-input>
							<div style="margin-top: 14px">
								<el-input placeholder="输入6位邮箱验证码" :maxlength="6" type="text" v-model="code">
									<template slot="append">
										<el-button
												style="border-radius: 0 4px 4px 0;width: 120px; line-height: 2px;"
												:style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '36px' : '40px', }"
												:disabled="countdown>0"
												@click="sendEmailCode"
										>
											{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
										</el-button>
									</template>
								</el-input>
							</div>
							<div style="text-align: center;margin-top: 40px;">
								<el-button @click="submitEmail" style="width: 320px;height: 36px; line-height: 10px; font-size: 18px;" type="primary">确认</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" :visible.sync="ToLogin" width="30%">
			<forgotToLogin></forgotToLogin>
		</el-dialog>
	</div>

</template>

<script>
	// import WxCode from "./WxCode";

	//  忘记密码
	import forgotToLogin from "./forgotToLogin"
	import store from "@/store";
	import {mapMutations} from "vuex";
	export default {
		computed: {
			user() {
				return this.$store.state.user;
			},
		},
		components: {
			// WxCode,
			forgotToLogin

		},
		props:{
			type:{
				type: Number,
				default() {
					return 0
				}
			},
		},
		data () {
			return {
				// 登录选择
				showLogin: true,
				selectLogin: 0,
				userOptions: [],
				guide: this.type,
				loginCallBack:'',
				// 新函数
				Login_list:[{type:2,name:'账号/邮箱登录'},{type:1,name:'验证码登录'}],
				codeType:'bgb',//扫码类型
				cache:2,
				phone:'',
				code:'',
				countdown: 0,
				//     账号和密码
				number:"",
				cipher:"",
				options: [],
				props: { multiple: true,},
				// 认证
				qr:'',
				first: false,
				qrShow:false,
				faceStatus: {},
				nameForm: {
					realName: '',
					idCardNo: '',
					userType: 1
				},
				nameRules: {
					realName: [
						{ required: true, message: '请输入真实姓名', trigger: 'blur' },
						{ pattern: /^[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}$/, message: '请输入真实姓名', trigger: 'blur'},
					],
					idCardNo: [
						{ required: true, message: '请输入正确身份证号码', trigger: 'blur' },
						{ pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
						{ min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
					],
				},
				organForm: {
					idCardNo: '',
					url: '',
					qualifications: [],
					certificate: []
				},
				organRules: {
					idCardNo: [
						{ required: true, message: '请输入正确的身份证号码', trigger: 'blur' },
						{ pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号码', trigger: 'blur'},
						{ min: 18, max: 18, message: '身份证号码格式错误', trigger: 'blur'}
					]

				},

				// 账号信息
				pwdRuleList: [{name: '包含8-20个字符', status: false}, {name: '包含数字', status: false}, {name: '包含字母', status: false}],
				accountInfo: {
					nickName:'',
					account: '',
					password: '',
					confirmPassword: '',
				},
				// 修改密码
				passwordForm: {
					oldPassword: '',
					newPassword: '',
					confirmPassword: ''
				},

				// 绑定邮箱
				email: '',

				// 绑定微信
				wx_code:"",
				wx_openid:"",
				// 忘记密码
				ToLogin:false,
			}
		},
		watch: {
			'accountInfo.industry': {
				handler(newVal, oldVal) {
					if (newVal.length > 5) {
						this.$message({
							message: '最多只支持选择5项',
							duration: 1500,
							type: 'warning'
						});
						this.$nextTick(() => {
							this.accountInfo.industry = oldVal;
						})
					}
				}
			}
		},
		created:function(){

		},
		mounted: function () {
			this.$EventBus.$on('faceData', (data) => {
				this.faceStatus = data;
				if (this.faceStatus.code == '200') {
					this.utils.sus(this.faceStatus.msg);
					this.$router.push('/');
					this.onClose();
					this.qrShow = false
				} else {
					this.utils.sus(this.faceStatus.msg);
					this.qrShow = false
				}
			})
			this.newApi.getTypeList({lv: 3}).then(res => {
				this.options = res.data;
			});
		},
		methods:{
			...mapMutations(['setShowLoginPopup', 'setPendingRoute']),
			onClose() {
				this.setShowLoginPopup(false);
				this.setPendingRoute(null); // 清除待导航的路径
				this.$emit('onClose', false);
			},

            // 切换选中
            changeSelect(index){
                let that = this;
                that.selectLogin = index
            },
			// 第三方登录
			LoginQQClick() {
				var _this = this;
				store.commit('setShowLoginPopup', false);
				this.newApi.qqOauth({}).then(resp =>{
					//window.open(resp.data.result, "_blank")
					var width = width || 900;
					var height = height || 540;
					var left = (window.screen.width - width) / 2;
					var top = (window.screen.height - height) / 2;
					var win =window.open(resp.data, "_blank",
							"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
							left + ", top=" + top + ", width=" + width + ", height=" + height);
					//监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
					var listener=setInterval(function(){
						//监听窗口是否关闭
						if(win.closed){
							//进入这个if代表后端验证成功!直接跳转路由
							clearInterval(listener);//关闭监听
							// //跳转路由
							// var path = _this.$route.query.redirect;
							// _this.$router.replace({
							// 	path:
							// 		path === "/" || typeof path === 'undefined' ? "/" : path
							// });
							// _this.$router.go(0) //刷新
							_this.$store.commit("get_user_info");
						}
					},500)

				}).catch(fail =>{
					console.error(fail)
				})
			},
			/**
			 *微信登陆
			 */
			LoginWXClick(){
				var _this = this;
				store.commit('setShowLoginPopup', false);
				this.newApi.wxOauth({"type":"login"}).then(resp =>{
					//window.open(resp.data.result, "_blank")
					var width = width || 900;
					var height = height || 540;
					var left = (window.screen.width - width) / 2;
					var top = (window.screen.height - height) / 2;
					var win =window.open(resp.data, "_blank",
							"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
							left + ", top=" + top + ", width=" + width + ", height=" + height);
					//监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
					var listener=setInterval(function(){
						//监听窗口是否关闭
						if(win.closed){
							//进入这个if代表后端验证成功!直接跳转路由
							clearInterval(listener);//关闭监听
							// //跳转路由
							// var path = _this.$route.query.redirect;
							// _this.$router.replace({
							// 	path:
							// 		path === "/" || typeof path === 'undefined' ? "/admin/dashboard" : path
							// });
							// _this.$router.go(0) //刷新
							_this.$store.commit("get_user_info");
						}
					},500)

				}).catch(fail =>{
					console.error(fail)
				})
			},

			/**
			 * 绑定qq
			 */
			bindQQ:function () {
				var _this = this;
				this.newApi.qqOauth({"type":"bind"}).then(resp =>{
					//window.open(resp.data.result, "_blank")
					var width = width || 900;
					var height = height || 540;
					var left = (window.screen.width - width) / 2;
					var top = (window.screen.height - height) / 2;
					var win =window.open(resp.data, "_blank",
							"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
							left + ", top=" + top + ", width=" + width + ", height=" + height);
					//监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
					var listener=setInterval(function(){
						if(win.closed){
							clearInterval(listener);//关闭监听
							_this.$router.go(0) //刷新
						}
					},500)
				}).catch(fail =>{
					console.error(fail)
				})
			},
			/**
			 * 绑定微信
			 */
			bindWx:function () {
				var _this = this;
				this.newApi.wxOauth({"type":"bind"}).then(resp =>{
					//window.open(resp.data.result, "_blank")
					var width = width || 900;
					var height = height || 540;
					var left = (window.screen.width - width) / 2;
					var top = (window.screen.height - height) / 2;
					var win =window.open(resp.data, "_blank",
							"toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, left=" +
							left + ", top=" + top + ", width=" + width + ", height=" + height);
					//监听登录窗口是否关闭,登录成功后 后端返回关闭窗口的代码
					var listener=setInterval(function(){
						if(win.closed){
							clearInterval(listener);//关闭监听
							_this.$router.go(0) //刷新
						}
					},500)
				}).catch(fail =>{
					console.error(fail)
				})
			},
			login_title(type){
				this.cache = type;
			},
			toReg(type){
				this.$router.push('/index/reg?type='+type);
				store.commit('setShowLoginPopup', false);
			},
			sendVerificationCode() {
				const that = this;
				if (this.countdown > 0) return;
				// this.$refs.form.validateField('phone', (errorMsg) => {
				//     if (!errorMsg) {
				this.newApi.sendSms({"mobile":that.phone,"smsType":3}).then((ret)=>{
					that.utils.sus(ret.data);
					this.startCountdown();
				}).catch((err)=>{
					that.utils.err(err);
				});
				// }
				// })
			},
			sendEmailCode() {
				const that = this;
				const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
				if (!regEmail.test(this.email)) {
					this.utils.err('请输入正确的邮箱账号');
					return;
				}
				if (this.countdown > 0) return;
				this.newApi.sendRegisterEmail({email:that.email}).then(()=>{
					this.startCountdown();
				}).catch((err)=>{
					that.utils.err(err);
				});
			},
			//倒计时
			startCountdown() {
				this.countdown = 60;
				const timer = setInterval(() => {
					this.countdown -= 1;
					if (this.countdown <= 0) {
						clearInterval(timer);
					}
				}, 1000);
			},
			pwd_login:function(){
				var that = this;
				let param = {};
				param.accoun = that.number;
				param.pwd = that.cipher;
				localStorage.token = "";
				that.newApi.getLoginAccount(param).then((res)=>{
					if(res.isSuccess == 1){
						that.utils.sus("登录成功");
						store.commit('setShowLoginPopup', false);
						localStorage.token = res.data.token;
						localStorage.uuid = res.data.uuid;
						store.commit("get_user_info",function (r) {
						that.newApi.updateLoginTime({uuid: res.data.uuid});
						if (res.data.guide != 0) {
							that.guide = res.data.guide;
						} else {
              window.location.reload()
            }
							const pendingRoute = that.$store.getters.getPendingRoute;
							if (pendingRoute) {
								that.$router.push(pendingRoute);
							}
							store.commit('setPendingRoute', null);

							})
					}
				})
			},

			//获取订阅信息
			getSubscribe(uuid) {
				var that = this;
				that.newApi.getSubscribe({ type: 1, uuid: uuid }).then((res) => {
					if (res.isSuccess == 1){
						that.$store.state.subList = res.data.subList;
					}
				})
			},


			// 绑定手机号
			submitPhone() {
				var that = this;
				let param = {};
				param.phone = that.phone;
				param.code = that.code;
				that.newApi.bindPhone(param).then((res)=>{
					if(res.isSuccess == 1){
						that.utils.sus(res.data);
						that.$router.push('/');
						that.onClose();
					}
				})
			},

			// 绑定邮箱
			submitEmail() {
				var that = this;
				let param = {};
				param.email = that.email;
				param.code = that.code;
				that.newApi.bindEmail(param).then((res)=>{
					if(res.isSuccess == 1){
						that.utils.sus(res.data);
						that.$router.push('/');
						that.onClose();
					}
				})
			},

			// 验证密码
			changePassword(e) {
				this.pwdRuleList[1].status = /\d/.test(e);
				this.pwdRuleList[2].status = /[a-zA-Z]/.test(e);
				this.pwdRuleList[0].status = e.length < 20 && e.length >= 8;
			},

			// 提交密码信息
			submitPasswordInfo() {
				if (!this.passwordForm.oldPassword) {
					this.utils.err('请输入旧的账号密码');
					return;
				}
				for (let i = 0; i < this.pwdRuleList.length; i++) {
					if (this.pwdRuleList[i].status === false) {
						this.utils.err('请输入正确的账号密码');
						return;
					}
				}
				if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
					this.utils.err('两次输入的密码不一致');
					return;
				}
				this.newApi.saveMyPwd({pwd: this.passwordForm.newPassword, oldPwd: this.passwordForm.oldPassword}).then((ret)=>{
					this.utils.sus(ret.data);
					this.onClose();
				})
			},

			// 提交账号信息
			submitAccountInfo() {
				if (!this.accountInfo.nickName) {
					this.utils.err('请输入昵称');
					return;
				}
				if (!this.accountInfo.account && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.accountInfo.account)) {
					this.utils.err('请输入8-20位字母和数字的组合');
					return;
				}
				if (!this.accountInfo.password) {
					this.utils.err('请输入账号密码');
					return;
				}
				for (let i = 0; i < this.pwdRuleList.length; i++) {
					if (this.pwdRuleList[i].status === false) {
						this.utils.err('请输入正确的账号密码');
						return;
					}
				}
				if (this.accountInfo.password !== this.accountInfo.confirmPassword) {
					this.utils.err('两次输入的密码不一致');
					return;
				}
				let params = this.accountInfo;
				this.newApi.bindAccount(params).then((res)=>{
					this.utils.sus(res.data);
					this.$router.push('/');
					this.onClose();
				})
			},
			//个人短信验证码登录
			accoun_login_code(){
				var that = this;
				let param = {};
				param.phone = that.phone;
				param.code = that.code;
				param.smsType = 3;
				localStorage.token = "";
				that.newApi.getLoginCode(param).then((res)=>{
					if(res.isSuccess == 1){
						if (res.data.length > 1) {
							that.userOptions = res.data;
							that.showLogin = false;
                            for (let i = 0; i < res.data.length; i++){
                                if(res.data[i].isDisabled == false){
                                    that.selectLogin = i;
                                    break;
                                }
                            }
						} else {
							that.utils.sus("登录成功");
							store.commit('setShowLoginPopup', true);
							localStorage.token = res.data[0].token;
							localStorage.uuid = res.data[0].uuid;
							that.$store.commit("get_user_info");
							that.newApi.updateLoginTime({uuid: res.data[0].uuid})
							if (res.data[0].guide != 0) {
								that.guide = res.data[0].guide;
								if (res.data[0].guide==1){
									that.newApi.getTypeList({lv: 3}).then(res => {
										that.options = res.data;
									});
								}
							} else {
                window.location.reload()
							}
						}
					}
				})
			},

			// 选择账号登录
			toLogin() {
				let that = this
                localStorage.token = '';
                localStorage.uuid = '';
                this.$store.commit("login_out");
				that.utils.sus("登录成功");
				store.commit('setShowLoginPopup', true);
				localStorage.token = that.userOptions[that.selectLogin].token;
				localStorage.uuid = that.userOptions[that.selectLogin].uuid;
				that.$store.commit("get_user_info");
				that.newApi.updateLoginTime({uuid: that.userOptions[that.selectLogin].uuid})
				if (that.userOptions[that.selectLogin].guide != 0) {
					that.guide = that.userOptions[that.selectLogin].guide;
					if (that.userOptions[that.selectLogin].guide==1){
						that.newApi.getTypeList({lv: 3}).then(res => {
							that.options = res.data;
						});
					}
				} else {
					that.newApi.getUserInfo({id: that.userOptions[that.selectLogin].uuid}).then(res =>{
						if (res.isSuccess === 1) {
              that.onClose();
              that.$nextTick(() => {
                if (res.data.authenticationStatus < 2 && res.data.userType === 1) {
                  store.commit('setShowAuthPopup', 'major');
                }
                if (res.data.realNameStatus !== 2 && res.data.userType === 2) {
                  store.commit('setShowAuthPopup', 'realName');
                }
              })

						} else {
							that.utils.err(res.data)
						}
					})
				}
			},

			// 忘记登录密码事件
			forgotTologin(){
				this.ToLogin = true;
			},

      cloneTologin(){
        this.ToLogin = false;
      }
		}
	}

</script>

<style scoped>
	*{
		font-size: 14px;
	}
	a{
		text-decoration:none;
		color:rgb(166, 166, 166);
	}
	.checkbox {
		zoom: 100%;
	}
	.bg_shadow{
		width:100%;
		height:100%;
		position:fixed;
		z-index:1213;
		background-color:rgba(0,0,0,.6);
		top:0;left:0;
	}
	.center_login{
		background:#fff;
		border-radius:10px;
		width:800px;
		height: 520px;
		position:absolute;
		margin:10% auto;
	}

	.center_phone {
		background:#fff;
		border-radius:10px;
		width:420px;
		position:absolute;
		margin:10% auto;
	}
	.card {
		width: 94%;
		height: 30px;
		display: flex;
		padding: 10px 20px;
		margin-bottom: 10px;
		position: relative;
		background-color: #F6F6F6;
	}
	.card .triangle{
		position:absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border: 14px solid #fff;
		border-bottom-color: #2970FF;
		border-right-color: #2970FF;
		border-top-color:transparent ;
		border-left-color: transparent;
	}

	.card .tr-icon {
		color: #FFFFFF;
		position:absolute;
		font-weight: 600;
	}
	.card {
		width: 94%;
		height: 30px;
		display: flex;
		padding: 10px 20px;
		margin-bottom: 10px;
		position: relative;
		background-color: #F6F6F6;
	}
	.card .triangle{
		position:absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border: 14px solid #fff;
		border-bottom-color: #2970FF;
		border-right-color: #2970FF;
		border-top-color:transparent ;
		border-left-color: transparent;
	}

	.card .tr-icon {
		color: #FFFFFF;
		position:absolute;
		font-weight: 600;
	}

	.card .btn {
		padding: 2px 10px;
		font-size: 14px;
		line-height: 24px;
		border-radius: 20px;
	}

	.select-border {
		border: 2px solid #2970FF;
		background-color: #FFFFFF;
	}

	.login_tabs{
		height:40px;
		overflow:hidden;
		margin:0 15px;
	}
	.login_tab{
		width:50%;
		float:left;
		height:40px;
		line-height:40px;
		text-align:center;
	}
	.active_login_tab{
		height:37px;
		border-bottom:3px solid #384a92;
		font-weight:bold;
	}
	.qrcode{
		text-align:center;
	}
	.qrcode img{
		text-align:center;
	}
	.denglu {
		width: 45px;
		height: 40px;
		background:url("~@/assets/imgs/login/denglu.png");
		outline:none;
		font-size: 20px;
		color: #000;
		position: absolute;
		left: 100px;
		top: 126px;
		z-index:5;

	}
	.value {
		border:none;
		background:#ffffff;
		outline:none;
	}

	::-webkit-input-placeholder {
		color: #999999;;
		font-size: 20px;

	}
	/deep/  .el-checkbox__inner {
		width: 19px;
		height: 19px;
	}
	/deep/ .el-checkbox__label {
		font-size: 16px;
		height: 19px;
	}

	.mama {
		width: 41px;
		height: 45px;
		background:url("~@/assets/imgs/login/mama.png");
		position: absolute;
		left: 103px;
		top: 196px;
		z-index:5;
	}

	/**
     新css样式
     */
	/deep/ .el-button {
		height:28px ;
		font-size: 12px;
	}
	/deep/ .el-button.is-round {
		padding: 5px 10px;
	}
	.Login {
		height: 21px;
		font-size: 18px;
		color: #444444;
		font-weight: bold;
		float: left;
		text-align: center;
		cursor: pointer;
	}
	.active {
		color: #2970FF;
		margin-left: 20px;
		border-bottom: 4px solid #2970FF;
	}
	.save-btn {
		width: 68px;
		height: 38px;
		color: #fff;
		background-color: #1C7EFF;
		border-radius: 5px;
	}
	.info-title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.upload-demo {
		vertical-align: middle;
	}

	.el-icon-plus {
		color: #2970FF;
		font-size: 44px;
		font-weight: 600;
		text-shadow: 0 3px 6px rgba(0,0,0,0.16);
		margin: 60px 0 14px 0;
	}

	/deep/ .el-upload-dragger {
		background-color: #FBFBFB;
	}

	/deep/ .type-input .el-input-group__prepend {
		border-top: none; /* 去除上边框 */
		border-left: none; /* 去除左边框 */
		border-right: none; /* 去除右边框 */
		border-bottom:1px solid #DCDFE6;
		background-color:#FFFFFF;
		padding: 0px 11px;
	}
	/deep/ .type-input .el-input__inner {
		border-top: none; /* 去除上边框 */
		border-left: none; /* 去除左边框 */
		border-right: none; /* 去除右边框 */

	}
	/deep/ .el-input__inner:focus{
		box-shadow: none; /* 去除选中时的边框阴影 */
		border-color: #c0c4cc; /* 重置边框颜色 */
	}

	/deep/ .type-input .el-input-group__append {
		border-top: none; /* 去除上边框 */
		border-left: none; /* 去除左边框 */
		border-right: none; /* 去除右边框 */
		border-bottom:1px solid #DCDFE6;
		background-color: #FFFFFF;
		color:#1C7EFF ;
		font-weight: bold;
		cursor: pointer;
	}
	/deep/ .el-button--primary {
		background-color: #2E57EE;
	}
	/deep/ .el-input-group__append, .el-input-group__prepend {
		background: #FFFFFF;
		color: #1C7EFF;
	}
</style>
